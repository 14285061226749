import { Text } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import {
  BASE_WIN_TITLE_SCALE,
  COUNT_UP_MESSAGE_X,
  COUNT_UP_MESSAGE_Y,
  eventManager,
  MAXIMUM_FRACTION_DIGITS,
  MINIMUM_FRACTION_DIGITS,
  winValueStyles,
  winValueStyles2,
} from '../config';

class CountUp extends ViewContainer {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winTitle: Text;

  private winTitle2: Text;

  constructor() {
    super();
    this.zIndex = 11;
    this.visible = false;
    this.winTitle = new Text(
      this.winValue.toLocaleString('en-EN', {
        minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
        maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
      }),
      winValueStyles,
    );
    this.winTitle2 = new Text(
      this.winValue.toLocaleString('en-EN', {
        minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
        maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
      }),
      winValueStyles2,
    );
    this.winTitle.y = COUNT_UP_MESSAGE_Y;
    this.winTitle.x = COUNT_UP_MESSAGE_X;
    this.winTitle.anchor.set(0.5, 0.5);
    this.winTitle2.y = 0;
    this.winTitle2.x = 0;
    this.winTitle2.anchor.set(0.5, 0.5);
    this.winTitle.addChild(this.winTitle2);
    this.addChild(this.winTitle);
    eventManager.addListener(EventTypes.START_COUNT_UP, this.startCountUp.bind(this));
    eventManager.addListener(EventTypes.HIDE_COUNT_UP, this.hideCountUp.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.winValue = 0.0;
    });
  }

  private hideCountUp(durationTime?: number): void {
    const duration = durationTime === undefined ? 1000 : 0;
    const hideCountUpMassage = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration,
    });
    hideCountUpMassage.addOnComplete(() => {
      this.visible = false;
      this.alpha = 1;
    });
    hideCountUpMassage.addOnSkip(() => {
      this.visible = false;
      this.alpha = 1;
    });
    hideCountUpMassage.start();
  }

  private startCountUp(start: number, end: number, id: number): void {
    this.visible = true;
    const normalizedStart = normalizeCoins(start);
    const normalizedEnd = normalizeCoins(end);
    const countUpAnimation = new AnimationChain();
    const baseWinAnimation = this.createBaseWinAnimation(normalizedStart, normalizedEnd, id);
    baseWinAnimation.addOnStart(() => {
      this.visible = true;
      AudioApi.play({ type: ISongs.CountUp_Loop, stopPrev: true });
    });
    baseWinAnimation.addOnComplete(() => {
      eventManager.emit(EventTypes.COUNT_UP_END);
      // TODO: need to find count up end sound
      AudioApi.stop({ type: ISongs.CountUp_Loop });
      AudioApi.play({ type: ISongs.CountUp_Stop });
      this.winCountUpAnimation = null;
    });
    baseWinAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.COUNT_UP_END);
      AudioApi.stop({ type: ISongs.CountUp_Loop });
      AudioApi.play({ type: ISongs.CountUp_Stop });
      this.setWinValue(normalizedEnd);
      this.winCountUpAnimation = null;
    });
    countUpAnimation.appendAnimation(baseWinAnimation);

    this.winCountUpAnimation = countUpAnimation;
    this.winCountUpAnimation.start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  private createBaseWinAnimation(start: number, end: number, id: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    const duration = 1000;
    const propertyBeginValueX = Math.min(1.4 + 0.1 * id, BASE_WIN_TITLE_SCALE);
    const propertyBeginValueY = Math.min(1.4 + 0.1 * id, BASE_WIN_TITLE_SCALE);
    const targetX = Math.min(1.4 + 0.1 * (id + 1), BASE_WIN_TITLE_SCALE);
    const targetY = Math.min(1.4 + 0.1 * (id + 1), BASE_WIN_TITLE_SCALE);
    const countUpAnimation = new Tween({
      propertyBeginValue: start,
      target: end,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: propertyBeginValueX,
      target: targetX,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: propertyBeginValueY,
      target: targetY,
      property: TweenProperties.Y,
      duration,
    });
    if (id !== -1) {
      baseWinAnimation.addAnimation(scaleXAnimation);
      baseWinAnimation.addAnimation(scaleYAnimation);
    }
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winTitle.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winTitle2.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
  }
}

export default CountUp;
