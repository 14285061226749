export * from './types';
export enum States {
  INIT = 'INIT',
  INTRO = 'INTRO',
  BROKEN_GAME = 'BROKEN_GAME',
  IDLE = 'IDLE',
  MYSTERY = 'MYSTERY',
  SPIN = 'SPIN',
  BEFORE_WIN = 'BEFORE_WIN',
  WIN_LINE_PRESENTATION = 'WIN_LINE_PRESENTATION',
  AFTER_WIN = 'AFTER_WIN',
  JINGLE = 'JINGLE',
  TRANSITION = 'TRANSITION',
}
