import { ITextStyle } from 'pixi.js';

const fontFamily = 'Noto Sans';

export const textWinAmount: Partial<ITextStyle> = {
  fill: ['#FFF6A4', '#FFE388', '#FF9900', '#FFEE54', '#FFB240'],
  fillGradientStops: [0.6],
  fontFamily,
  fontSize: 150,
  lineJoin: 'round',
  miterLimit: 110,
  stroke: '#464646',
  strokeThickness: 25,
  whiteSpace: 'normal',
  fontWeight: '900',
};

export const textYouWon: Partial<ITextStyle> = {
  align: 'center',
  fill: '#C0F4FF',
  fontFamily,
  fontSize: 125,
  fontWeight: '900',
  miterLimit: 48,
  stroke: '#213A73',
  strokeThickness: 25,
  whiteSpace: 'normal',
  lineJoin: 'round',
};

export const congratulationTextStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: '#FFFFFF',
  fontFamily,
  fontSize: 70,
  fontWeight: '900',
  miterLimit: 48,
  stroke: '#213A73',
  strokeThickness: 25,
  whiteSpace: 'normal',
  lineJoin: 'round',
};

export const titleTextStyle: Partial<ITextStyle> = {
  fill: '#C0F4FF',
  fontFamily,
  fontSize: 120,
  lineJoin: 'round',
  stroke: '#213A73',
  strokeThickness: 25,
  whiteSpace: 'normal',
  fontWeight: '900',
};
export const valueTextStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: 'white',
  fontFamily,
  fontSize: 300,
  miterLimit: 48,
  stroke: '#213A73',
  lineJoin: 'round',
  strokeThickness: 40,
  whiteSpace: 'normal',
  dropShadowBlur: 27,
  dropShadowColor: '#C0F4FF',
  dropShadowDistance: 0,
  fontWeight: '900',
};
