import { Container } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
// import { goToLobby } from '@money.energy/utils-fe';
import { ISongs } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsOpenMenuPopup,
  setIsPopupOpened,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import { Logic } from '../../logic';
import {
  calcPercentage,
  handleChangeRestriction,
  isMobileLandscape,
  isMobilePortrait,
  isRegularMode,
  isTabletLandscape,
  isTabletPortrait,
} from '../../utils';
import {
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_UI_DESKTOP_H,
  BTN_UI_DESKTOP_W,
  BTN_UI_MOBILE_H,
  BTN_UI_MOBILE_W,
  BTN_UI_TABLET_H,
  BTN_UI_TABLET_W,
  eventManager,
} from '../config';
import { UiButton } from '../ui/uiButton';

class MenuBtn extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private menuContainer = new Container();

  private isOpened = false;

  private isFreeSpinsWin = false;

  constructor() {
    super('menu');
    this.interactive = !this.isDisabled;
    this.btn.on('click', () => this.openMenu());
    this.btn.on('touchstart', () => this.openMenu());
    this.initSubscriptions();
    this.handleDisable();
    this.name = 'ControlBtn';
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions(): void {
    AudioApi.on('interrupted', (isInterrupted: boolean) => {
      if (isInterrupted) {
        AudioApi.unSuspend();
      }
    });
    AudioApi.on('suspended', (isSuspended) => {
      if (isSuspended) {
        AudioApi.unSuspend();
      } else {
        handleChangeRestriction(setGameMode());
      }
    });
    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      setTimeout(() => {
        AudioApi.unSuspend();
        AudioApi.processRestriction({
          restricted: false,
        });
        if (setBrokenGame()) {
          AudioApi.play({ type: ISongs.FreeSpinsBackground });
        }
      });
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP_BG, () => {
      this.visible = false;
    });
    eventManager.on(EventTypes.CLOSE_POPUP_BG, () => {
      this.visible = true;
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleCloseMenu();
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
      this.handleCloseMenu();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleCloseMenu();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
  }

  private openMenu(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      AudioApi.play({ type: ISongs.UI_ButtonPress });
      eventManager.emit(EventTypes.OPEN_POPUP_BG);
      setIsOpenMenuPopup(true);
      this.visible = false;
    }
  }

  private handleCloseMenu(): void {
    this.visible = true;
    eventManager.emit(EventTypes.CLOSE_POPUP_BG);
  }

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(Logic.the.controller.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        setIsPopupOpened() ||
        setIsContinueAutoSpinsAfterFeature(),
    );
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0);
    const ratio = this.applicationSize.width / this.applicationSize.height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_UI_DESKTOP_H : BTN_UI_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_TABLET_H : BTN_UI_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_MOBILE_H : BTN_UI_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = this.applicationSize.height;
    } else {
      deviceSize = this.applicationSize.width;
    }

    if (this.isPortraitMode) {
      deviceSize = this.applicationSize.height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);
    const x = this.applicationSize.width - gap;
    const y = gap;
    this.setSize(size);

    this.x = x;
    this.y = y;
  }
}

export default MenuBtn;
