import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useReactiveVar } from '@apollo/client';
import { formatNumber } from '@money.energy/utils-fe';
import { config } from '../../../config';
import { setCoinValue, setSlotConfig, setUserBalance } from '../../../gql/cache';
import { getBetAmountGql } from '../../../gql/query';
import { ResourceTypes } from '../../../resources.d';
import { countCoins, showCurrency } from '../../../utils';
import Resources from '../../../utils/resources';
import type { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, _slug: ResourceTypes): number => {
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSets[0]!.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;

  const { currency } = userBalance.balance;

  return (
    <div>
      <div className={`${styles['paytable-list']} ${styles['wild']}`}>
        <div className={styles['paytable-list__item']}>
          <div className={styles['img']}>
            <div className={styles['image__title']}>{t(`infoPayTable_wild`)}</div>
            <img draggable="false" alt={'wild'} src={Resources.getSource('paytableWild')} />
          </div>
          <table className={styles['content']}>
            <tbody>
              {data[1].combos.map((i) => (
                <tr key={i.pattern}>
                  <td>
                    <span className={styles['multiplier']}>{i.pattern} </span>
                    {formatNumber({
                      currency: currency,
                      value: calcMultiplier(i.multiplier, betAmount, ResourceTypes.wild),
                      showCurrency: showCurrency(currency),
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles['additional']}>{t('infoPayTableWildText')}</div>
        </div>
        <div className={styles['paytable-list__item']}>
          <div className={styles['img']}>
            <div className={styles['image__title']}>{t(`infoPayTable_scatter`)}</div>
            <img draggable="false" alt={'wild'} src={Resources.getSource('paytableScatter')} />
          </div>
        </div>
      </div>

      <div className={styles['paytable-list']}>
        {data.map(
          ({ combos, slug }) =>
            slug !== ResourceTypes.wild &&
            slug !== ResourceTypes.scatter && (
              <div key={slug} className={styles['paytable-list__item']}>
                <div className={styles['img']}>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                  {/* <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div> */}
                </div>
                <table className={styles['content']}>
                  <tbody>
                    {combos.map((i) => (
                      <tr key={i.pattern}>
                        <td>
                          <span className={styles['multiplier']}>{i.pattern} </span>
                          {formatNumber({
                            currency: currency,
                            value: calcMultiplier(i.multiplier, betAmount, slug),
                            showCurrency: showCurrency(currency),
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default PaytableComponent;
