import type { RemoteStorage } from '@money.energy/setting-store-client';
import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface Payline {
  lineId: number | null;
  winPositions: number[];
  amount: number;
}
export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    data: {
      bonuses: UserBonus[];
      features: {
        freeSpinsCount: number;
        isFreeSpins: boolean;
        mystery: {
          mysteryMatrix: MysteryMatrix[];
        };
      };
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    createdAt: string;
    updatedAt: string;
  };
  paylines: Payline[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  FREE_SPIN = 'FREE_SPIN',
}

export type UserBonus = {
  bonusId: string;
  bonus: {
    type: string;
  };
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  betId: string;
  coinAmount: number;
  rounds: number;
  roundsPlayed: number;
  data: {
    freeSpinsFeature?: IFreeSpinFeature;
  };
  reelSetId?: string;
  totalWinAmount: number;
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  isActive: boolean;
  bonusId: string;
  roundsPlayed: number;
  currentRound: number;
  rounds: number;
  reelSetId: string;
  totalWinAmount: number;
  betId: string;
}

export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};

export type BuyFeatureEnterProps = {
  bonusId: string;
};
export type FreeSpinsEnterProps = {
  bonus: UserBonus;
};
export type EnterProps = (BuyFeatureEnterProps | FreeSpinsEnterProps) & {
  immediate?: boolean;
};

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

export enum GameMode {
  BASE_GAME,
  FREE_SPINS,
  BUY_FEATURE,
}

export enum FeatureState {
  FREE_SPINS,
}

export const baseReelSetIds = ['6bf1addc-9650-4737-9681-96d1c56f4dfa', 'd045b6ed-3fc2-484a-9ac1-f285efb83b4e'];

export const bonusesId = {
  [GameMode.FREE_SPINS]: '9f915072-031a-4aca-9064-1dbf371f7ee4',
};

export const buyFeatureBonusesId = {
  [FeatureState.FREE_SPINS]: 'c4c26a3c-f513-46ab-b972-b525fc6217f8',
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  mobileTitlePosition?: number;
  titleStyles?: TextStyle;
  mobileTitleStyle?: TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  mobileSubtitlePosition?: number;
  subtitleStyles?: TextStyle;
  mobileSubtitleStyle?: TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: TextStyle;
  mobileAdditionalStyle?: TextStyle;
  preventDefaultDestroy?: boolean;
  mobileSubtitleBorderWidth?: number;
  mobileSubtitleBorderHeight?: number;
  subtitleBorderWidth?: number;
  subtitleBorderHeight?: number;
  callback?: () => void;
  onInitCallback?: () => void;
};

export enum EventTypes {
  BROKEN_GAME = 'brokenGame',
  CHANGE_MODE = 'changeMode',
  COUNT_UP_END = 'countUpEnd',

  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAYTABLE = 'disablePaytable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  ENABLE_PAYTABLE = 'enablePaytable',

  FORCE_STOP_REELS = 'forceStopReels',
  FORCE_RESIZE = 'forceResize',
  GAME_READY = 'gameReady',
  HIDE_COUNT_UP = 'hideCountUp',
  CHANGE_REELS_DATA = 'changeReelsData',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  REELS_STOPPED = 'reelsStopped',
  REEL_LANDED = 'reelLanded',
  REEL_LANDED_ANIMATION_PLAYED = 'reelLandedAnimationPlayed',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_COUNT_UP = 'startCountUp',
  START_BIG_WIN_PRESENTATION = 'startBigWinPresentation',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_CURRENT_RESULT_MINI_PAYTABLE = 'setCurrentResultMiniPayTable',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_ANIMATION = 'skipWinAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_POPUP = 'openPopup',
  OPEN_POPUP_BG = 'openPopupBg',
  CLOSE_POPUP = 'closePopup',
  CLOSE_POPUP_BG = 'closePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  WIN_LINE_ANIMATION_END = 'winLineAnimationEnd',
  SHOW_WIN_LINES = 'showWinLines',
  HIDE_WIN_LINES = 'hideWinLines',
  SHOW_TINT = 'showTint',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SHOW_COINS = 'showCoins',
  HIDE_COINS = 'hideCoins',
  END_BIG_WIN_PRESENTATION = 'endBigWinPresentation',
  FORCE_STOP_AUTOPLAY = 'FORCE_STOP_AUTOPLAY',
  SET_IS_FREESPINS_WIN = 'SET_IS_FREESPINS_WIN',
  END_FREE_SPINS = 'END_FREE_SPINS',
  NEXT_FREE_SPINS_ROUND = 'NEXT_FREE_SPINS_ROUND',
  UPDATE_FREE_SPINS_AMOUNT = 'UPDATE_FREE_SPINS_AMOUNT',
  UPDATE_TOTAL_WIN_VALUE = 'UPDATE_TOTAL_WIN_VALUE',
  START_FREE_SPINS = 'START_FREE_SPINS',
  CREATE_FREESPIN_POPUP = 'CREATE_FREESPIN_POPUP',
  SHOW_BACKDROP = 'showBackDrop',
  HIDE_BACKDROP = 'hideBackDrop',
  MYSTERY_SYMBOLS_REVEAL_START = 'MYSTERY_SYMBOLS_REVEAL_START',
  MYSTERY_SYMBOLS_REVEAL_END = 'MYSTERY_SYMBOLS_REVEAL_END',
  TRANSITION_START = 'TRANSITION_START',
  TRANSITION_END = 'TRANSITION_END',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
}

export enum FreeSpinPopupTypes {
  Freespin,
  Total,
}

export type FreeSpinsPopupProps = {
  bannerBg: string;
  title?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  subtitle?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  additionalText?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  btn?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  value?: {
    text: string;
    position: 0;
    styles: PIXI.TextStyle;
  };
  preventDefaultDestroy?: boolean;
  type?: MessageBannerTypes;
  callback?: () => void;
  onInitCallback?: () => void;
};
