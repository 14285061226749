import i18n from 'i18next';
import { Container, isMobile, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setCurrentBonus, setIsContinueAutoSpinsAfterFeature, setIsDuringBigWinLoop } from '../../../gql/cache';
import type Animation from '../../animations/animation';
import { TextField } from '../../components/TextField';
import type { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';
import { titleTextStyle, valueTextStyle } from '../textStyles';
import FreeSpinsPopupBackgroundAnimated from './freeSpinsPopupBackgroundAnimated';

export class FreeSpinsPopup extends Popup {
  protected background!: Sprite;

  protected popupBackground!: FreeSpinsPopupBackgroundAnimated;

  protected title!: TextField;

  protected rounds!: TextField;

  protected pressToContinueText!: TextField;

  protected textContainer!: ViewContainer;

  public fadeOutAnimation!: Animation;

  public fadeInAnimation!: Animation;

  public backgroundFadeInAnimation!: Animation;

  public backgroundFadeOutAnimation!: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.initBackground();
    this.init();
  }
  private initBackground(): void {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.background.alpha = 0.5;
    this.popupBackground = new FreeSpinsPopupBackgroundAnimated(this);
  }

  private initTitle(): void {
    this.title = new TextField(i18n.t('FREE SPINS'), 900, 400, titleTextStyle);
    this.title.text.y = -170;
    this.title.text.anchor.set(0.5, 0.5);
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.popupBackground);
    this.initTitle();
    this.initValueText();
    const content = new Container();
    content.addChild(this.title.text, this.rounds.text);
    this.popupBackground.spine.skeleton.findSlot('place_holder').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton.findSlot('place_holder').currentSprite.addChild(content);
  }

  private initValueText(): void {
    this.rounds = new TextField('', 500, 400, valueTextStyle);
    this.rounds.text.anchor.set(0.5, 0.5);
    this.rounds.text.y = 50;
  }

  public override show(_props?: PopupProps): void {
    AudioApi.play({ type: ISongs.FreeSpinStart });
    setTimeout(() => {
      AudioApi.play({ type: ISongs.FreeSpinsBackground });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.FreeSpinsBackground);
      }
    }, 4000);

    this.rounds.text.text = `${setCurrentBonus().rounds}`;
    this.popupBackground.show();
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    AudioApi.stop({ type: ISongs.FreeSpinStart });
    AudioApi.play({ type: ISongs.FreeSpinClose });
    clearTimeout(this.autoSkipDelay);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide();
  };

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    const factor = (isMobile.any && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.popupBackground.scale.set(factor);
    this.position.set(width / 2, height / 2);
  }
}
