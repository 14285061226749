import AudioApi from '@money.energy/audio-api';
import { ISongs, SlotId } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import {
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeSpinsTotalWin,
  setGameHistory,
  setGameMode,
  setIsFreeSpinsWin,
  setIsPopupOpened,
  setIsProceedToGame,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setLastSpinData,
  setSlotConfig,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { eventManager, WinStages } from '../../slotMachine/config';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import { getBetResult, getSpinResult, getWinStage, playWinSound } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';
import { Controller } from './Controller';

export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public override enterInitState(_prevState: States): void {
    Logic.the.changeState(States.INTRO);
    return;
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProceedToGame(true);
    }
  }

  public override enterTransitionState(_prevState: States): void {
    eventManager.emit(EventTypes.TRANSITION_START);
  }

  public override exitTransitionState(_prevState: States): void {
    eventManager.emit(EventTypes.TRANSITION_END);
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.initIntroScreen();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterBrokenGameState(_prevState: States): void {
    setIsProceedToGame(true);
    if (!setCurrentBonusId()) {
      const bonus = setCurrentBonus();
      SlotMachine.the().onBrokenGame(bonus);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(bonus.gameMode, { bonus, immediate: true });
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);

      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (prevState === States.TRANSITION) {
      setIsSpinInProgress(false);
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      if (setCurrentBonusId()) {
        return;
      }
    }

    if (prevState === States.INIT || prevState === States.INTRO) {
      // const debug = new Debug();
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.stop({ type: ISongs.Background });
      AudioApi.play({ type: ISongs.LibraryAmbience });
    }, 20000);
    if (prevState === States.BROKEN_GAME) {
      const intervalId = window.setInterval(() => {
        if (eventManager.listenerCount(EventTypes.TOGGLE_SPIN) > 0) {
          setIsPopupOpened(false);
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          window.clearInterval(intervalId);
        }
      }, 50);
      return;
    }
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory(prevState === States.TRANSITION);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout!);
    if (AudioApi.isPlaying(ISongs.LibraryAmbience)) {
      AudioApi.stop({ type: ISongs.LibraryAmbience });
      AudioApi.play({ type: ISongs.Background });
    }
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterMysteryState(_prevState: States): void {
    eventManager.emit(EventTypes.MYSTERY_SYMBOLS_REVEAL_START);
    eventManager.once(EventTypes.MYSTERY_SYMBOLS_REVEAL_END, () => {
      Logic.the.changeState(States.BEFORE_WIN);
    });
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    this.checkBaseWin(betResult);
  }

  public override enterWinLinePresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { paylines } = betResult;
    const { winCoinAmount, spinResult } = betResult.bet.result;
    const scatterCount = spinResult.filter((e) => e.id === SlotId.SC1).length;

    if (getWinStage(winCoinAmount) >= WinStages.BigWin && scatterCount < 3) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      eventManager.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
        if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
          eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
            Logic.the.changeState(States.AFTER_WIN);
          });
          setTimeout(() => {
            eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
          }, 0);
        } else {
          Logic.the.changeState(States.AFTER_WIN);
        }
      });
      if (scatterCount < 3) {
        eventManager.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
      }
    }

    eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setWinAmount(winCoinAmount);
    setLastRegularWinAmount(winCoinAmount);
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    const isFeatureWin = result.bet.data.bonuses.length > 0;
    const { winCoinAmount } = result.bet.result;
    playWinSound(winCoinAmount);

    if (isFeatureWin) {
      const [bonus] = result.bet.data.bonuses;
      const isFreeSpinsFeature = Boolean(!(bonus as UserBonus).data.freeSpinsFeature);

      if (isFreeSpinsFeature) {
        setIsFreeSpinsWin(true);
        setCurrentBonus({
          ...(bonus as UserBonus),
          isActive: true,
          currentRound: 0,
        });
        setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
        Logic.the.skipWinAnimation();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.FREE_SPINS, {
          bonus: bonus as UserBonus,
        });
      }

      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps): void {
    setGameMode(GameMode.BASE_GAME);
    AudioApi.play({ type: ISongs.LibraryAmbience });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    if (prevGameMode === GameMode.FREE_SPINS) {
      setWinAmount(setFreeSpinsTotalWin());
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitController(_nextGameMode: GameMode): void {
    clearTimeout(this.slotIdleTimeout!);
    AudioApi.stop({ type: ISongs.LibraryAmbience });
    AudioApi.stop({ type: ISongs.Background });
  }

  public override setResult(result: ISettledBet): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    result.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
      mysteryMatrix: result.bet.data.features.mystery?.mysteryMatrix,
    });
    setBetResult(result);
    Logic.the.currentSpinResult = result.bet.result.spinResult;

    setUserLastBetResult(result.bet);
    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });
  }

  private handleHistory(_skipSoundChange: boolean): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];

    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
  }

  private checkBaseWin(betResult: ISettledBet): void {
    const paylines = betResult.paylines;

    if (paylines.length) {
      Logic.the.changeState(States.WIN_LINE_PRESENTATION);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }
}
