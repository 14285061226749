import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs, SlotId } from '../../config';
import { EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import {
  setBetResult,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsSlotBusy,
  setLastRegularWinAmount,
  setLastSpinData,
  setReelSets,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses, isStoppedGql, slotBetGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { eventManager, PopupTypes, WinStages } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import {
  getBetResult,
  getBGMSoundByGameMode,
  getSpinResult,
  getWinStage,
  isFreeSpinsFinished,
  playWinSound,
  showCurrency,
} from '../../utils';
import { States } from '../config';
import { Logic } from '../index';
import { BaseController } from './BaseController';

export class FreeSpinController extends BaseController {
  public override gameMode: GameMode = GameMode.FREE_SPINS;

  public static override the = new FreeSpinController();

  protected constructor() {
    super();
  }

  public override enterIdleState(_prevState: States): void {
    setIsSlotBusy(false);
    if (isFreeSpinsFinished()) {
      AudioApi.fadeOut(1000, getBGMSoundByGameMode(this.gameMode));
      PopupController.the.openPopup(PopupTypes.FREE_SPINS_END);
      eventManager.once(EventTypes.END_FREE_SPINS, () => {
        this.getLastSpinData().then((res) => {
          PopupController.the.closeCurrentPopup();
          setLastSpinData(res);
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.BASE_GAME);
        });
      });
      return;
    }
    setTimeout(() => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND), 500);
  }

  public override enterSpinState(_prevState: States): void {
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    SlotMachine.the().spinSpinAnimation();
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
  }

  public override enterTransitionState(_prevState: States): void {
    eventManager.emit(EventTypes.TRANSITION_START);
  }

  public override exitTransitionState(_prevState: States): void {
    eventManager.emit(EventTypes.TRANSITION_END);
  }

  public override enterMysteryState(_prevState: States): void {
    eventManager.emit(EventTypes.MYSTERY_SYMBOLS_REVEAL_START);
    eventManager.once(EventTypes.MYSTERY_SYMBOLS_REVEAL_END, () => {
      Logic.the.changeState(States.BEFORE_WIN);
    });
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    const paylines = betResult.paylines;

    if (paylines.length) {
      Logic.the.changeState(States.WIN_LINE_PRESENTATION);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterJingleState(_prevState: States): void {
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    playWinSound(winCoinAmount);
    if (isFreeSpinsFinished()) {
      setTimeout(() => {
        Logic.the.changeState(States.IDLE);
      }, 1000);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
      mysteryMatrix: getBetResult(result).bet.data.features?.mystery?.mysteryMatrix || [],
    });
    eventManager.emit(EventTypes.UPDATE_FREE_SPINS_AMOUNT, setCurrentBonus().currentRound, setCurrentBonus().rounds);
    setFreeSpinsTotalWin(setFreeSpinsTotalWin() + result.bet.result.winCoinAmount);
    result.bet.result.spinResult = spinResult;
    setBetResult(result);
    Logic.the.currentSpinResult = result.bet.result.spinResult;
  }

  public override enterController(_prevGameMode: GameMode): void {
    setGameMode(GameMode.FREE_SPINS);
    if (setBrokenGame()) AudioApi.play({ type: ISongs.FreeSpinsBackground });
    setIsSlotBusy(false);

    eventManager.emit(EventTypes.UPDATE_FREE_SPINS_AMOUNT, setCurrentBonus().currentRound, setCurrentBonus().rounds);
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setFreeSpinsTotalWin() > 0) {
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({
          currency: setCurrency(),
          value: setFreeSpinsTotalWin(),
          showCurrency: showCurrency(setCurrency()),
        }),
      );
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.IDLE);
      return;
    }

    if (setIsContinueAutoSpinsAfterFeature()) {
      AudioApi.play({ type: ISongs.FreeSpinsBackground });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.FreeSpinsBackground);
      }
      Logic.the.changeState(States.IDLE);
    } else {
      eventManager.once(EventTypes.START_FREE_SPINS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.IDLE);
      });
      PopupController.the.openPopup(PopupTypes.FREE_SPINS);
    }
  }

  public async getLastSpinData(): Promise<{
    reelPositions: number[];
    layout: SlotId[][];
  }> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    // todo replace with real backend logic
    const { betId } = res.data.userBonuses[0]!;
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });
    const { reelPositions, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    } as { reelPositions: number[]; winCountAmount: number; reelSetId: string };
    const layout = setReelSets().find((reelSet) => reelSet.id === reelSetId)?.layout;
    setUserLastBetResult(bet.data.bet);
    setBetResult({ ...getBetResult(setBetResult()), bet: { ...bet.data.bet } });
    return { reelPositions, layout: layout || [] };
  }

  public override enterAfterWinState(_prevState: States): void {
    this.onEnterAfterWinState();
  }

  private onEnterAfterWinState(): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
  }

  public override enterWinLinePresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { paylines } = betResult;
    const { winCoinAmount, spinResult } = betResult.bet.result;
    const scatterCount = spinResult.filter((e) => e.id === SlotId.SC1).length;

    if (getWinStage(winCoinAmount) >= WinStages.BigWin && scatterCount < 3) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        if (setFreeSpinsTotalWin() > 0) {
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({
              currency: setCurrency(),
              value: setFreeSpinsTotalWin(),
              showCurrency: showCurrency(setCurrency()),
            }),
          );
        }
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      eventManager.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
        if (setFreeSpinsTotalWin() > 0) {
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({
              currency: setCurrency(),
              value: setFreeSpinsTotalWin(),
              showCurrency: showCurrency(setCurrency()),
            }),
          );
        }
        if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
          eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
            if (setFreeSpinsTotalWin() > 0) {
              eventManager.emit(
                EventTypes.UPDATE_WIN_VALUE,
                formatNumber({
                  currency: setCurrency(),
                  value: setFreeSpinsTotalWin(),
                  showCurrency: showCurrency(setCurrency()),
                }),
              );
            }
            Logic.the.changeState(States.AFTER_WIN);
          });
          setTimeout(() => {
            eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
          }, 0);
        } else {
          Logic.the.changeState(States.AFTER_WIN);
        }
      });
      if (scatterCount < 3) {
        eventManager.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
      }
    }

    eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
  }

  public override exitController(_nextGameMode: GameMode): void {
    Logic.the.skipWinAnimation();
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioApi.stop({ type: ISongs.FreeSpinsBackground });
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
  }
}
