import type React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18next';
import styles from './info.module.scss';

const Features: React.FC = () => {
  const { t } = useTranslation();

  const features1 = [
    { key: 'infoFeatures1_1', text: i18n.t('infoFeatures1_1') },
    { key: 'infoFeatures1_2', text: i18n.t('infoFeatures1_2') },
    { key: 'infoFeatures1_3', text: i18n.t('infoFeatures1_3') },
    { key: 'infoFeatures1_4', text: i18n.t('infoFeatures1_4') },
    { key: 'infoFeatures1_5', text: i18n.t('infoFeatures1_5') },
  ];
  const features2 = [
    { key: 'infoFeatures2_1', text: i18n.t('infoFeatures2_1') },
    { key: 'infoFeatures2_2', text: i18n.t('infoFeatures2_2') },
    { key: 'infoFeatures2_3', text: i18n.t('infoFeatures2_3') },
  ];
  const features3 = [
    { key: 'infoFeatures3_1', text: i18n.t('infoFeatures3_1') },
    { key: 'infoFeatures3_2', text: i18n.t('infoFeatures3_2') },
    { key: 'infoFeatures3_3', text: i18n.t('infoFeatures3_3') },
    { key: 'infoFeatures3_4', text: i18n.t('infoFeatures3_4') },
    { key: 'infoFeatures3_5', text: i18n.t('infoFeatures3_5') },
    { key: 'infoFeatures3_6', text: i18n.t('infoFeatures3_6') },
  ];

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoFeaturesTitle')}</h1>
      <h1 className={styles['subtitle']}>{t('infoFeaturesSubtitleWild')}</h1>
      <div className={styles['row']}>
        {features1.map((v) => (
          <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
      <h1 className={styles['subtitle']}>{t('infoFeaturesSubtitleMS')}</h1>
      <div className={styles['row']}>
        {features3.map((v) => (
          <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
      <h1 className={styles['subtitle']}>{t('infoFeaturesSubtitleFS')}</h1>
      <div className={styles['row']}>
        {features2.map((v) => (
          <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
    </div>
  );
};

export default Features;
