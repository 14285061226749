import { ResourceTypes } from '../resources.d';
import { MysteryType } from '../slotMachine/d';
import Resources from '../utils/resources';
import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.high1 },
  [SlotId.B]: { default: ResourceTypes.high2 },
  [SlotId.C]: { default: ResourceTypes.high4 },
  [SlotId.D]: { default: ResourceTypes.high5 },
  [SlotId.E]: { default: ResourceTypes.high3 },
  [SlotId.F]: { default: ResourceTypes.low1 },
  [SlotId.G]: { default: ResourceTypes.low2 },
  [SlotId.H]: { default: ResourceTypes.low3 },
  [SlotId.I]: { default: ResourceTypes.low4 },
  [SlotId.J]: { default: ResourceTypes.low5 },
  [SlotId.SC1]: { default: ResourceTypes.scatter },
  [SlotId.SC]: { default: ResourceTypes.scatter },
  [SlotId.WL]: { default: ResourceTypes.wild },
  [SlotId.MS1]: { default: ResourceTypes.mysteryGold },
};

export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: { default: ResourceTypes.high1Motion },
  [SlotId.B]: { default: ResourceTypes.high2Motion },
  [SlotId.C]: { default: ResourceTypes.high4Motion },
  [SlotId.D]: { default: ResourceTypes.high5Motion },
  [SlotId.E]: { default: ResourceTypes.high3Motion },
  [SlotId.F]: { default: ResourceTypes.low1Motion },
  [SlotId.G]: { default: ResourceTypes.low2Motion },
  [SlotId.H]: { default: ResourceTypes.low3Motion },
  [SlotId.I]: { default: ResourceTypes.low4Motion },
  [SlotId.J]: { default: ResourceTypes.low5Motion },
  [SlotId.SC1]: { default: ResourceTypes.scatterMotion },
  [SlotId.SC]: { default: ResourceTypes.scatterMotion },
  [SlotId.WL]: { default: ResourceTypes.wildMotion },
  [SlotId.MS1]: { default: ResourceTypes.mysteryGold },
};

export const MAPPED_MYSTERY_SYMBOLS: Record<MysteryType, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [MysteryType.DEFAULT]: { default: ResourceTypes.mysteryBrown },
  [MysteryType.GOLDEN]: { default: ResourceTypes.mysteryGold },
  [MysteryType.FREE_SPINS]: { default: ResourceTypes.mysteryBlue },
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Librarian_Win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Hourglass_Win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'AncientScroll_Win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Quill_n_Ink_Win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Candles_Win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'A_Win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'K_Win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Q_Win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'J_Win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: '10_Win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'Wild_Win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'FreeSpin_Win',
  },
  [SlotId.SC]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'FreeSpin_Win',
  },
  [SlotId.MS1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'FreeSpin_Win',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  // [SlotId.WL]: {
  //   type: SymbolAnimationType.SPINE,
  //   src: 'collect_symbol',
  //   animation: 'collect_symbol_land',
  // },
  // [SlotId.SC1]: {
  //   type: SymbolAnimationType.SPINE,
  //   src: 'symbols',
  //   animation: 'scatter_land',
  // },
};

export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.low1, src: Resources.getSource(ResourceTypes.low1) },
  {
    name: ResourceTypes.low2,
    src: Resources.getSource(ResourceTypes.low2),
  },
  {
    name: ResourceTypes.low3,
    src: Resources.getSource(ResourceTypes.low3),
  },
  { name: ResourceTypes.low4, src: Resources.getSource(ResourceTypes.low4) },
  {
    name: ResourceTypes.low5,
    src: Resources.getSource(ResourceTypes.low5),
  },
  {
    name: ResourceTypes.high1,
    src: Resources.getSource(ResourceTypes.high1),
  },
  {
    name: ResourceTypes.high2,
    src: Resources.getSource(ResourceTypes.high2),
  },
  {
    name: ResourceTypes.high3,
    src: Resources.getSource(ResourceTypes.high3),
  },
  {
    name: ResourceTypes.high4,
    src: Resources.getSource(ResourceTypes.high4),
  },
  {
    name: ResourceTypes.high5,
    src: Resources.getSource(ResourceTypes.high5),
  },
  {
    name: ResourceTypes.scatter,
    src: Resources.getSource(ResourceTypes.scatter),
  },
  {
    name: ResourceTypes.wild,
    src: Resources.getSource(ResourceTypes.wild),
  },
  { name: ResourceTypes.low1Motion, src: Resources.getSource(ResourceTypes.low1Motion) },
  {
    name: ResourceTypes.low2Motion,
    src: Resources.getSource(ResourceTypes.low2Motion),
  },
  {
    name: ResourceTypes.low3Motion,
    src: Resources.getSource(ResourceTypes.low3Motion),
  },
  { name: ResourceTypes.low4Motion, src: Resources.getSource(ResourceTypes.low4Motion) },
  {
    name: ResourceTypes.low5Motion,
    src: Resources.getSource(ResourceTypes.low5Motion),
  },
  {
    name: ResourceTypes.high1Motion,
    src: Resources.getSource(ResourceTypes.high1Motion),
  },
  {
    name: ResourceTypes.high2Motion,
    src: Resources.getSource(ResourceTypes.high2Motion),
  },
  {
    name: ResourceTypes.high3Motion,
    src: Resources.getSource(ResourceTypes.high3Motion),
  },
  {
    name: ResourceTypes.high4Motion,
    src: Resources.getSource(ResourceTypes.high4Motion),
  },
  {
    name: ResourceTypes.high5Motion,
    src: Resources.getSource(ResourceTypes.high5Motion),
  },
  {
    name: ResourceTypes.mysteryBlue,
    src: Resources.getSource(ResourceTypes.mysteryBlue),
  },
  {
    name: ResourceTypes.mysteryBrown,
    src: Resources.getSource(ResourceTypes.mysteryBrown),
  },
  {
    name: ResourceTypes.mysteryGold,
    src: Resources.getSource(ResourceTypes.mysteryGold),
  },
  {
    name: ResourceTypes.scatterMotion,
    src: Resources.getSource(ResourceTypes.scatterMotion),
  },
  {
    name: ResourceTypes.wildMotion,
    src: Resources.getSource(ResourceTypes.wildMotion),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.betBackground, src: Resources.getSource(ResourceTypes.betBackground) },
  { name: ResourceTypes.betMinusBtn, src: Resources.getSource(ResourceTypes.betMinusBtn) },
  { name: ResourceTypes.betMinusBtnDisable, src: Resources.getSource(ResourceTypes.betMinusBtnDisable) },
  { name: ResourceTypes.betMinusBtnHover, src: Resources.getSource(ResourceTypes.betMinusBtnHover) },
  { name: ResourceTypes.betMinusBtnPressed, src: Resources.getSource(ResourceTypes.betMinusBtnPressed) },
  { name: ResourceTypes.betPlusBtn, src: Resources.getSource(ResourceTypes.betPlusBtn) },
  { name: ResourceTypes.betPlusBtnDisable, src: Resources.getSource(ResourceTypes.betPlusBtnDisable) },
  { name: ResourceTypes.betPlusBtnHover, src: Resources.getSource(ResourceTypes.betPlusBtnHover) },
  { name: ResourceTypes.betPlusBtnPressed, src: Resources.getSource(ResourceTypes.betPlusBtnPressed) },

  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  { name: ResourceTypes.frameFs, src: Resources.getSource(ResourceTypes.frameFs) },
  {
    name: ResourceTypes.reelBackground,
    src: Resources.getSource(ResourceTypes.reelBackground),
  },
  {
    name: 'reelAmbient',
    src: '/animations/reelAmbient/Reel.json',
  },
  {
    name: 'symbols',
    src: '/animations/symbols/symbols.json',
  },
  {
    name: 'fs-popup',
    src: '/animations/popup/FS_popup.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/Win_PopUps_2x.json',
  },
  {
    name: 'spritesheet',
    src: '/images/ui/spritesheet.json',
  },
  {
    name: 'ambient',
    src: '/animations/ambient/Base_BG_Book_of_Books.json',
  },
  {
    name: 'gameLogo',
    src: '/animations/logo/gameLogo.json',
  },
  {
    name: 'mystery',
    src: '/animations/mystery/Mystery_Symbols.json',
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.slotBgFs,
    src: Resources.getSource(ResourceTypes.slotBgFs),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
];
