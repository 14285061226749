import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';
import { GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';

class ReelsFrame extends ViewContainer {
  private frame = new Sprite(Texture.from(ResourceTypes.frame));
  private frameAmbient = new Spine(Loader.shared.resources['reelAmbient']!.spineData!);

  constructor() {
    super();
    this.sortableChildren = true;
    this.init();
  }

  private init(): void {
    this.frameAmbient.y = 75;
    this.frameAmbient.x = 0;
    this.frameAmbient.name = 'frameAnim';
    this.frameAmbient.state.setAnimation(0, 'FS_reel', true);
    this.frameAmbient.visible = false;
    this.frameAmbient.scale.set(1.17);
    this.frame.anchor.set(0.5);
    this.frame.y = -55;
    this.frame.x = 0;
    this.frame.scale.set(1.17);
    this.interactiveChildren = false;
    this.addChild(this.frame, this.frameAmbient);
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges(settings.mode);
        break;
      case GameMode.FREE_SPINS:
        this.handleBaseModeChanges(settings.mode);
        break;
      default:
        this.handleBaseModeChanges(settings.mode);
        break;
    }
  }

  public handleBaseModeChanges(mode: GameMode): void {
    if (mode === GameMode.FREE_SPINS) {
      this.frame.texture = Texture.from(ResourceTypes.frameFs);
      this.frameAmbient.visible = true;
    } else {
      this.frame.texture = Texture.from(ResourceTypes.frame);
      this.frameAmbient.visible = false;
    }
  }
}

export default ReelsFrame;
