import i18n from 'i18next';
import { Container, isMobile, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setCurrency, setFreeSpinsTotalWin, setIsContinueAutoSpinsAfterFeature } from '../../../gql/cache';
import { normalizeCoins, showCurrency } from '../../../utils';
import { TextField } from '../../components/TextField';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';
import { congratulationTextStyle, textWinAmount, textYouWon } from '../textStyles';
import FreeSpinsEndPopupBackgroundAnimated from './freeSpinsEndPopupBackgroundAnimated';

export class FreeSpinsEndPopup extends Popup {
  protected background!: Sprite;

  protected popupBackground!: FreeSpinsEndPopupBackgroundAnimated;

  protected winValue!: TextField;

  protected youWonText!: TextField;

  protected congratulationText!: TextField;

  protected pressToContinueText!: TextField;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.initBackground();
    this.initCongratulationText();
    this.initYouWonText();
    this.initWinValueText();

    this.init();
  }

  private initBackground(): void {
    this.popupBackground = new FreeSpinsEndPopupBackgroundAnimated(this);
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.background.alpha = 0.5;
  }

  private initYouWonText(): void {
    this.youWonText = new TextField(i18n.t('freeSpinsYouWon'), 900, 400, textYouWon);
    this.youWonText.text.anchor.set(0.5, 0.5);
    this.youWonText.text.y = -50;
    this.popupBackground.spine.addChild(this.youWonText.getText());
  }

  private initCongratulationText(): void {
    this.congratulationText = new TextField(i18n.t('freeSpinsCongratulation'), 900, 400, congratulationTextStyle);
    this.congratulationText.text.anchor.set(0.5, 0.5);
    this.congratulationText.text.y = -250;
  }

  private initWinValueText(): void {
    this.winValue = new TextField('', 900, 400, textWinAmount);
    this.winValue.text.anchor.set(0.5, 0.5);
    this.winValue.text.y = 150;
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.popupBackground);
    const content = new Container();
    content.addChild(this.congratulationText.text, this.youWonText.text, this.winValue.text);
    this.popupBackground.spine.skeleton.findSlot('place_holder').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton.findSlot('place_holder').currentSprite.addChild(content);
  }

  public override show(_props?: PopupProps): void {
    AudioApi.stop({ type: ISongs.FreeSpinsBackground });
    AudioApi.play({ type: ISongs.FreeSpinEnd });
    this.winValue.setText(
      `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`,
    );
    this.popupBackground.show();
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    AudioApi.stop({ type: ISongs.FreeSpinEnd });
    AudioApi.play({ type: ISongs.FreeSpinClose });
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    clearTimeout(this.autoSkipDelay);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide();
  };

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    const factor = (isMobile.any && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.popupBackground.scale.set(factor);
    this.position.set(width / 2, height / 2);
  }
}
