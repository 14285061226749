import i18n from 'i18next';
import _ from 'lodash';
import { Loader, LoaderResource } from 'pixi.js';
import { ELoaderStages, type ILoaderResource } from '@money.energy/shared-components/dist/loader/d';
import type { IResource } from '@money.energy/shared-components/dist/resources/d';
import { config, SlotId } from '../config';
import { setCoinValue, setCurrentBonus, setStressful } from '../gql/cache';
import { MAXIMUM_FRACTION_DIGITS, MINIMUM_FRACTION_DIGITS } from '../slotMachine/config';
import { Features, MysteryType } from '../slotMachine/d';

interface IPixiAssets {
  name: string;
  src: string;
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    Loader.shared.onError.once(() => {
      return reject();
    });
  });
};

export const loadPixiAssets = (assets: IPixiAssets[], baseUrl: string): Promise<void> => {
  Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => Loader.shared.add(asset.name, asset.src));
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result = await Promise.allSettled(promises);
      const failed = _.filter(
        result,
        (asset: { status: string }) => asset.status === 'rejected',
      ) as PromiseRejectedResult[];

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = (rejected.reason as { source: string; key: string }).source as string;
            asset.onload = () => {
              if (cb) cb((rejected.reason as { source: string; key: string }).key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const getCssVariable = (cssVar: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVar);
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (width < height) {
    return height * (parseInt(getCssVariable('--bottom-height-percent-portrait'), 10) / 100);
  }
  return height * (parseInt(getCssVariable('--bottom-height-percent-landscape'), 10) / 100);
};

export const getFromMappedSymbol = <T>(map: Record<SlotId, { default: T; freespin?: T }>, id: SlotId): T =>
  map[id as SlotId].default;

export const getFromMysteryMappedSymbol = <T>(
  map: Record<MysteryType, { default: T; freespin?: T }>,
  id: MysteryType,
): T => map[id as MysteryType].default;

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0): number => {
  return (coins * setCoinValue()) / 100;
};

export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const formatNumber = (currency = 'FUN', value = 0, showCurrency = false): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const browserLocale = new Intl.Locale(navigator.language || 'us');
  const currentLocale = urlParams.get('lng') || browserLocale.baseName;
  const locale =
    currentLocale.length > 2
      ? currentLocale
      : browserLocale.region
      ? `${currentLocale}-${browserLocale.region}`
      : currentLocale;
  if (currency === 'FUN') {
    return showCurrency
      ? `${currency} ${new Intl.NumberFormat('en', {
          minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
          maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        }).format(value)}`
      : new Intl.NumberFormat('en', {
          minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
          maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        }).format(value);
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const isFreeSpinsFinished = (): boolean => {
  const { currentRound, rounds } = setCurrentBonus();
  return currentRound === rounds;
};
